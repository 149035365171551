import './App.css';
import Home from './views/home';
function App() {
  console.log('here 2');
  return (
    <div className="App">
     <Home/>
    </div>
  );
}

export default App;
